.card {
  background-color: var(--mantine-color-body);
  width: 100%;
  cursor: pointer;
}

.title {
  font-weight: bold;
  font-family:
    Greycliff CF,
    var(--mantine-font-family);
  line-height: 1.2;
}

.body {
  padding-left: var(--mantine-spacing-xs);
}

.author {
  flex-wrap: nowrap;
}

@media only screen and (max-width: 1300px) {
  .author {
    flex-wrap: wrap;
  }
}