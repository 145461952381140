/*================================================
Pagination CSS
=================================================*/
.pagination-area {
  margin-top: 35px;
  display: flex;
  justify-content: center;
  
}

.pagination-area .page-numbers {
  width: 33px;
  height: 33px;
  background-color: #f3f0f0;
  color: #606060;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  line-height: 34px;
  position: relative;
  margin-left: 3px;
  margin-right: 3px;
  font-size: 16px;
  font-weight: 800;
}

.pagination-area .page-numbers:hover,
.pagination-area .page-numbers.current {
  color: #ffffff;
  background-color: #fe4a55;
}

.pagination-area .page-numbers i {
  position: relative;
  top: 1.5px;
}

@media only screen and (max-width: 767px) {
  .pagination-area {
    margin-top: 20px;
  }
}
