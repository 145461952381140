.footer-area {
  background-color: #100f1f;
  position: relative;
  z-index: 1;
  padding-top: 75px;
}
.single-footer-widget {
  margin-bottom: 30px;
}

.single-footer-widget .logo {
  display: inline-block;
  margin-bottom: 20px;
}

.single-footer-widget h3 {
  margin-bottom: 20px;
  position: relative;
  color: #ffffff;
  font-size: 24px;
  font-weight: 800;
}

.single-footer-widget p {
  color: #ededed;
}

.single-footer-widget .footer-links-list {
  padding-left: 0;
  margin-top: 0.7rem;
  margin-bottom: 0;
  list-style-type: none;
}

.single-footer-widget .footer-links-list li {
  margin-bottom: 12px;
  color: #ededed;
}

.single-footer-widget .footer-links-list li a {
  color: #ededed;
  display: inline-block;
}

.single-footer-widget .footer-links-list li a:hover {
  color: #fe4a55;
}

.single-footer-widget .footer-links-list li:last-child {
  margin-bottom: 0;
}

.single-footer-widget .social-link {
  padding-left: 0;
  list-style-type: none;
  margin-top: 20px;
  margin-bottom: 0;
}

.single-footer-widget .social-link li {
  display: inline-block;
  margin-right: 10px;
}

.single-footer-widget .social-link li:last-child {
  margin-right: 0;
}

.single-footer-widget .social-link li:last-child {
  margin-right: 0;
}

.single-footer-widget .social-link li a {
  width: 30px;
  height: 30px;
  text-align: center;
  color: #ffffff;
  background-color: #1e1d2f;
  font-size: 18px;
  position: relative;
  border-radius: 2px;
}

.single-footer-widget .social-link li a:hover {
  background-color: #fe4a55;
  color: #ffffff;
}

.footer-bottom-area {
  border-top: 2px solid #181726;
  padding: 1rem;
}

.footer-bottom-area p {
  color: #ededed;
}

.footer-bottom-area p a {
  color: #fe4a55;
  font-weight: 600;
}

.footer-bottom-area p a:hover {
  color: #ffffff;
}

.footer-bottom-area ul {
  text-align: right;
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.footer-bottom-area ul li {
  color: #ededed;
  position: relative;
  display: inline-block;
  margin-right: 20px;
}

.footer-bottom-area ul li:last-child {
  margin-right: 0;
}

.footer-bottom-area ul li:last-child::before {
  display: none;
}

.footer-bottom-area ul li::before {
  content: "";
  position: absolute;
  right: -12px;
  top: 5.5px;
  width: 1px;
  height: 14px;
  background-color: #ededed;
}

.footer-bottom-area ul li a {
  display: block;
  color: #ededed;
}

.footer-bottom-area ul li a:hover {
  color: #fe4a55;
}

.lines {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  margin: auto;
  width: 100%;
  z-index: -1;
}

.lines .line {
  position: absolute;
  width: 1px;
  height: 100%;
  top: 0;
  left: 50%;
  background: rgba(255, 255, 255, 0.1);
  overflow: hidden;
}

.lines .line::after {
  content: "";
  display: block;
  position: absolute;
  height: 15vh;
  width: 100%;
  top: -50%;
  left: 0;
  background: linear-gradient(to bottom,
          rgba(255, 255, 255, 0) 0%,
          #ffffff 75%,
          #ffffff 100%);
}

.lines .line:nth-child(1) {
  margin-left: -25%;
}

.lines .line:nth-child(1)::after {
  animation-delay: 2s;
}

.lines .line:nth-child(3) {
  margin-left: 25%;
}

.lines .line:nth-child(3)::after {
  animation-delay: 2.5s;
}


.social {
  @media (max-width: $mantine-breakpoint-sm) {
      margin-top: var(--mantine-spacing-xs);
  }
}

.socialIcon:hover {
  background-color: #fe4a55;
  color: #ffffff;
}

@keyframes run {
  0% {
      top: -50%;
  }

  100% {
      top: 110%;
  }
}

.footer-area,
.accordion-item button,
.footer-links-list li a {
  color: #ffffff;
  /* White text for all interactive elements */
}

.footer-links-list {
  padding-left: 10px;
}

/* Remove list dots for all lists in the footer */
.footer-links-list{
  list-style-type: none;
  /* Removes bullet points */
  padding-left: 0;
  /* Remove any default padding */
  margin-left: 0;
  /* Remove any default margin */
}

.footer-links-list li {
  margin-bottom: 12px;
  /* Add some space between items */
}

.footer-links-list li a:hover {
  color: #fe4a55;
  /* Optional hover state */
}

.accordion-button {
  color: #ffffff; /* Ensure the button text is white */
}

.accordion-button:hover {
  color: #fe4a55; /* Optional hover color for better interaction feedback */
}

.accordion-control {
  color: #ffffff !important; /* Ensure the color inside the accordion controls is visible */
}

.accordion-panel {
  color: #ededed; /* Light color for content inside the panel */
}

/* Generic Desktop (1024px and above) */
@media (min-width: 1024px) {
  .footer-grid-container {
      display: grid;
      grid-gap: 2%;
      grid-template-columns: 33.3% 33.3% 33.3%;
      justify-items: center;
  }
}

/* Generic Tablet (between 768px and 991px) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-area {
      padding-top: 80px;
  }

  .single-footer-widget h3 {
      font-size: 21px;
      margin-bottom: 19px;
  }

  .footer-bottom-area {
      margin-top: 50px;
  }
}

/* Generic Tablet (between 768px and 1023px) */
@media (min-width: 768px) and (max-width: 1023px) {
  .footer-grid-container {
      display: grid;
      grid-gap: 2%;
      grid-template-columns: 30% 30% 40%;
      justify-items: center;
  }
}

/* Mobile Devices (below 768px) */
@media only screen and (max-width: 767px) {
  .footer-area {
      color: #ffffff;
      padding: 30px 10px 10px 10px;
  }

  .footer-links-list {
      padding-left: 10px !important;
  }

  .single-footer-widget h3 {
      font-size: 20px;
  }

  .single-footer-widget.pl-5,
  .single-footer-widget.px-5 {
      padding-left: 0 !important;
  }

  .footer-bottom-area {
      text-align: center;
      padding-top: 20px;
      padding-bottom: 20px;
  }

  .footer-bottom-area ul {
      text-align: center;
      margin-top: 15px;
  }

  .footer-bottom-area ul li::before {
      top: 3px;
  }

  .footer-grid-container {
      display: grid;
      grid-gap: 2%;
      grid-template-columns: 100%;
      justify-items: center;
  }

  .single-footer-widget {
      margin-bottom: 20px;
      padding: 0 10px;
      /* Add some padding for mobile view */
  }

  .footer-grid-container {
      display: block;
      /* Stack sections in mobile */
      padding: 0 15px;
  }

  .accordion-item button {
      color: #ffffff;
      /* White text for the accordion control */
      font-weight: 600;
      /* Make the text a bit bolder for better visibility */
  }

  .accordion-item button:hover {
      color: #fe4a55;
      /* Optional: add a hover color for better interaction feedback */
  }

  .accordion-item button[aria-expanded="true"] {
      color: #fe4a55;
      /* Change color when the accordion is open */
  }

  .footer-bottom-wrapper {
      display: flex;
      flex-direction: column;
      /* Stack the items vertically */
      justify-content: space-between;
      /* Space between items */
      align-items: center;
      /* Center items horizontally */
      text-align: center;
      /* Center text */
  }


  .footer-policy-links {
      justify-content: center;
      /* Center links */
      flex-wrap: wrap;
      gap: 10px;
  }

  /* Remove dividers for mobile view */
  .footer-policy-links li::before {
      display: none;
  }

}