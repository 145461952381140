.notFoundContainer {
    text-align: center;
    margin: 100px auto;
    max-width: 400px;
}

.title {
    color: #ff4154;
}

.description {
    color: #797799;
    margin-bottom: 20px;
}

.link {
    display: inline-block;
    padding: 10px 20px;
    background-color: #100f1e;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.link:hover {
    background-color: #100f1e;
}
