.footer-area {
    background-color: #100f1f;
    position: relative;
    z-index: 1;
    padding-top: 100px;
}

.single-footer-widget {
    margin-bottom: 30px;
}

.single-footer-widget .logo {
    display: inline-block;
    margin-bottom: 20px;
}

.single-footer-widget h3 {
    margin-bottom: 20px;
    position: relative;
    color: #ffffff;
    font-size: 24px;
    font-weight: 800;
}

.single-footer-widget p {
    color: #ededed;
}

.single-footer-widget .footer-links-list {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
}

.single-footer-widget .footer-links-list li {
    margin-bottom: 12px;
    color: #ededed;
}

.single-footer-widget .footer-links-list li a {
    color: #ededed;
    display: inline-block;
}

.single-footer-widget .footer-links-list li a:hover {
    color: #fe4a55;
}

.single-footer-widget .footer-links-list li:last-child {
    margin-bottom: 0;
}

.single-footer-widget .social-link {
    padding-left: 0;
    list-style-type: none;
    margin-top: 20px;
    margin-bottom: 0;
}

.single-footer-widget .social-link li {
    display: inline-block;
    margin-right: 10px;
}

.single-footer-widget .social-link li:last-child {
    margin-right: 0;
}

.single-footer-widget .social-link li:last-child {
    margin-right: 0;
}

.single-footer-widget .social-link li a {
    width: 30px;
    height: 30px;
    text-align: center;
    color: #ffffff;
    background-color: #1e1d2f;
    font-size: 18px;
    position: relative;
    border-radius: 2px;
}

.single-footer-widget .social-link li a:hover {
    background-color: #fe4a55;
    color: #ffffff;
}

.single-footer-widget .footer-contact-info {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
}

.single-footer-widget .footer-contact-info li {
    margin-bottom: 13px;
    color: #ededed;
    position: relative;
}

.single-footer-widget .footer-contact-info li a {
    color: #ededed;
    display: inline-block;
}

.single-footer-widget .footer-contact-info li a:hover {
    color: #fe4a55;
}

.footer-bottom-area {
    border-top: 2px solid #181726;
}

.footer-bottom-area p {
    color: #ededed;
}

.footer-bottom-area p a {
    color: #fe4a55;
    font-weight: 600;
}

.footer-bottom-area p a:hover {
    color: #ffffff;
}

.footer-bottom-area ul {
    text-align: right;
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
}

.footer-bottom-area ul li {
    color: #ededed;
    position: relative;
    display: inline-block;
    margin-right: 20px;
}

.footer-bottom-area ul li:last-child {
    margin-right: 0;
}

.footer-bottom-area ul li:last-child::before {
    display: none;
}

.footer-bottom-area ul li::before {
    content: "";
    position: absolute;
    right: -12px;
    top: 5.5px;
    width: 1px;
    height: 14px;
    background-color: #ededed;
}

.footer-bottom-area ul li a {
    display: block;
    color: #ededed;
}

.footer-bottom-area ul li a:hover {
    color: #fe4a55;
}

.lines {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    margin: auto;
    width: 100%;
    z-index: -1;
}

.lines .line {
    position: absolute;
    width: 1px;
    height: 100%;
    top: 0;
    left: 50%;
    background: rgba(255, 255, 255, 0.1);
    overflow: hidden;
}

.lines .line::after {
    content: "";
    display: block;
    position: absolute;
    height: 15vh;
    width: 100%;
    top: -50%;
    left: 0;
    background: linear-gradient(to bottom,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 75%,
    #ffffff 100%);
    animation: run 7s 0s infinite;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
}

.lines .line:nth-child(1) {
    margin-left: -25%;
}

.lines .line:nth-child(1)::after {
    animation-delay: 2s;
}

.lines .line:nth-child(3) {
    margin-left: 25%;
}

.lines .line:nth-child(3)::after {
    animation-delay: 2.5s;
}

@keyframes run {
    0% {
        top: -50%;
    }

    100% {
        top: 110%;
    }
}

@media only screen and (max-width: 767px) {
    .footer-area {
        padding-top: 60px;
    }
    .single-footer-widget h3 {
        font-size: 20px;
    }
    .single-footer-widget.pl-5, .single-footer-widget.px-5 {
        padding-left: 0 !important;
    }
    .footer-bottom-area {
        margin-top: 30px;
        text-align: center;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .footer-bottom-area ul {
        text-align: center;
        margin-top: 15px;
    }
    .footer-bottom-area ul li::before {
        top: 3px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .footer-area {
        padding-top: 80px;
    }
    .single-footer-widget h3 {
        font-size: 21px;
        margin-bottom: 19px;
    }
    .footer-bottom-area {
        margin-top: 50px;
    }
}

.social {
    @media (max-width: $mantine-breakpoint-sm) {
        margin-top: var(--mantine-spacing-xs);
    }
}

.socialIcon:hover {
    background-color: #fe4a55;
    color: #ffffff;
}