.title {
    font-size: rem(26px);
    font-weight: 900;
    font-family:
      Greycliff CF,
      var(--mantine-font-family);
  }
  
  .controls {
    @media (max-width: $mantine-breakpoint-xs) {
      flex-direction: column-reverse;
    }
  }
  
  .control {
    @media (max-width: $mantine-breakpoint-xs) {
      width: 100%;
      text-align: center;
    }
  }